import { useState, useEffect } from "react";
import { CHANGE_PASSWORD } from "../../../data/forms";
import { Form as AntdForm } from "antd";
import { Popover, Button } from "antd";
import { Form } from "../..";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = AntdForm.useForm();
  const handleSave = (values) => {
    setLoading(true);
    props
      ?.request({
        url: `/user/changepass`,
        method: "POST",
        body: {
          oldpass: values.oldpass,
          newpass: values.confirmpassword,
        },
        ismessage: true,
      })
      .then((res) => {
        if (res.success) {
          form.resetFields();
        }
        setLoading(false);
      });
  };

  useEffect(() => {
    form?.setFieldValue("userid", props?.loclist);
  }, [props?.loclist]);

  return (
    <Popover
      trigger="click"
      content={
        <div className="">
          <Form
            {...props}
            form={form}
            loading={loading}
            handleSave={handleSave}
            className="mt-2"
            initialValues={{ carid: props?.data?.id }}
            data={CHANGE_PASSWORD}
          />
        </div>
      }
      title={props?.translate("main.changepass")}
    >
      <Button className="rounded-md p-2 mt-2 w-full flex items-center border">
        <span className="font-semibold">
          {props?.translate("main.changepass")}
        </span>
      </Button>
    </Popover>
  );
};

export default Index;
