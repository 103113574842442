import { Button } from "antd";

const Index = (props) => {
  const handleChoose = () => {
    props?.setActive(props?.data);
  };

  return (
    <div
      className={`p-4 text-center bg-white border rounded shadow h-32  dark:bg-gray-800 ${
        props?.active?.id === props?.data?.id ? "border-active" : "border-white"
      }`}
    >
      <div className="flex items-center justify-between h-full">
        <div className="flex flex-col justify-between h-full">
          <span className="p-2 mr-2  font-semibold text-white bg-gray-700 rounded dark:bg-gray-400">
            {props?.data?.name}
          </span>
        </div>
        <div className="flex flex-col justify-between h-full">
          <p className="text-3xl font-bold text-black dark:text-white">
            <span className="text-sm">₮</span>
            {new Intl.NumberFormat().format(props?.data?.price || 0)}
          </p>
          <Button
            type="primary"
            className="h-fit w-full"
            loading={props?.loading}
            onClick={handleChoose}
          >
            {props?.translate("main.choose")}
          </Button>
        </div>
      </div>
    </div>
  );
};

export default Index;
