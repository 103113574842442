import React, { useState } from "react";
import dayjs from "dayjs";
import { Modal } from "antd";
import { Cards } from "../../components";
const { info } = Modal;
const Index = (props) => {
  const onClickItem = (data) => {
    info({
      title: data?.header,
      content: (
        <div>
          <p>{data?.note}</p>
        </div>
      ),
      centered: true,
      okText: props?.translate("main.close"),
      onOk() {},
    });
    if (data?.isview === 0) {
      props
        .request({
          url: `/notification/user/detail/${data?.id}`,
        })
        .then((res) => {
          if (res.success) {
            data.isview = 1;
            props.request({
              url: "/notification/user",
              model: "notifications",
            });
          }
        });
    }
  };

  return (
    <div className="flex flex-col px-20 w-full gap-4">
      {props?.resnotifications?.map((item, i) => (
        <Cards.NotificationCard key={i} data={item} onClick={onClickItem} />
      ))}
    </div>
  );
};

export default Index;
