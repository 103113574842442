import { MENU } from "../../data";
import { useEffect, useState } from "react";
import { Button, Dropdown, Menu, Form } from "antd";
import { useNavigate, useLocation } from "react-router-dom";
import { LangSwitch, Modal } from "..";
import ComponentForm from "../Form";
import iLogOut from "../../assets/icons/iLogout.png";
import { FEEDBACK_ADD_FORM } from "../../data/forms";
import { UserType } from "../../enums";

const Index = (props) => {
  const [currentPage, setCurrentPage] = useState(1);
  const [isOpen, setIsOpen] = useState(false);
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location?.pathname) {
      let found = MENU?.find((x) => x.route === location?.pathname);
      if (found) setCurrentPage(found?.id);
    }
  }, [location]);

  const handleMenuClick = (id, route) => {
    if (route) {
      if (route === "/list/feedback" && props?._auth?.user?.type !== 1)
        setIsOpen(true);
      else {
        setCurrentPage(id);
        navigate(route);
      }
    }
  };

  const getDropDown = (menu) => {
    let tmp = [];
    let viewWindow = menu?.childs?.filter((x) =>
      x.role?.find((y) => y === props?._auth?.user?.type)
    );
    viewWindow?.map((child, i) => {
      tmp.push(
        <Menu.Item
          onClick={() => handleMenuClick(menu?.id, child?.route)}
          key={i}
        >
          {props?.translate(child?.label)}
        </Menu.Item>
      );
    });

    return (
      <Dropdown
        overlay={<Menu>{tmp}</Menu>}
        className="w-12 h-12 flex items-center justify-center"
        shape="circle"
        render
        trigger={["click"]}
        placement="bottomRight"
        icon={<img className="icon w-6 h-6" src={menu?.icon} alt="icon" />}
      >
        <Button
          className="border-0 w-12 h-12 flex items-center justify-center"
          shape="circle"
          type={menu?.id === currentPage ? "primary" : ""}
          icon={
            <img
              className={`w-6 h-6 ${
                menu?.id === currentPage ? "active-icon" : "icon"
              }`}
              src={menu?.icon}
              alt="icon"
            />
          }
        />
      </Dropdown>
    );
  };

  const getMainMenu = (menu) => {
    return (
      <Button
        onClick={() => handleMenuClick(menu?.id, menu?.route)}
        className="w-12 h-12 flex items-center justify-center border-0"
        shape="circle"
        type={menu?.route === location?.pathname ? "primary" : ""}
        icon={
          <img
            className={`w-6 h-6 ${
              menu?.route === location?.pathname ? "active-icon" : "icon"
            }`}
            src={menu?.icon}
            alt="icon"
          />
        }
      />
    );
  };

  const getMenus = () => {
    let tmp = [];
    MENU?.map((menu, i) => {
      if (menu?.isMenu) {
        if (menu?.childs?.length) {
          if (menu?.role) {
            if (menu?.role?.includes(props?._auth?.user?.type)) {
              tmp.push(getDropDown(menu));
            }
          } else tmp.push(getDropDown(menu));
        } else {
          if (menu?.role) {
            if (menu?.role?.includes(props?._auth?.user?.type)) {
              tmp.push(getMainMenu(menu));
            }
          } else {
            tmp.push(getMainMenu(menu));
          }
        }
      }
    });

    return tmp;
  };

  const handleLogOut = () => {
    localStorage.removeItem("access_token");
    localStorage.removeItem("auth");
    props.setlogin(false);
    navigate("/login");
  };

  const onClose = () => {
    form.resetFields();
    setIsOpen(false);
  };

  const handleSave = (e) => {
    setLoading(true);
    let tmp = {
      type: e.type,
      text: e.text,
    };
    props
      .request({
        url: `/feedback`,
        method: "POST",
        body: tmp,
        ismessage: true,
      })
      .then((res) => {
        if (res.success) {
          form.resetFields();
          setIsOpen(false);
        }
        setLoading(false);
      });
  };

  return (
    <div className="z-50 bg-white dark:bg-card dark:border-border items-center border rounded-2xl p-3 absolute md:relative bottom-0 w-full md:w-fit flex flex-col justify-between">
      <div className="gap-5 flex md:flex-col items-center justify-between md:justify-normal w-full">
        {getMenus()}
      </div>
      <div className="justify-center items-center w-full hidden md:flex md:flex-col">
        <LangSwitch {...props} />
        <Button
          className="flex items-center justify-center w-full h-fit mt-5"
          onClick={handleLogOut}
          type="ghost"
          icon={<img src={iLogOut} alt="iLogOut" className="icon h-7" />}
        />
      </div>
      <Modal
        title={props?.translate("main.savefeedback")}
        open={isOpen}
        onClose={onClose}
        width={400}
        isShowFooter={false}
      >
        <div className="p-5">
          <ComponentForm
            loading={loading}
            handleSave={handleSave}
            form={form}
            btnText={props?.translate("main.send")}
            data={FEEDBACK_ADD_FORM}
            {...props}
          />
        </div>
      </Modal>
    </div>
  );
};

export default Index;
