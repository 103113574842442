import Formatter from "../utils/formatter";
import { CONCAR_FORM, CONUSER_FORM } from "./forms";

const InsNameColumn = {
  title: "Бүртгэсэн",
  dataIndex: "insbyname",
  key: "insbyname",
  width: 100,
};

const EditColumn = {
  title: "",
  dataIndex: "edit",
  key: "edit",
  width: 65,
  align: "center",
  isExport: false,
  fixed: "right",
  render: (value, record, index) => (
    <Formatter value={value} format="edit" index={index} record={record} />
  ),
};

const NoColumn = {
  title: "№",
  dataIndex: "no",
  key: "no",
  width: 50,
  align: "center",
  render: (value, row, index) => index + 1,
};

const StatusColumn = {
  title: "Төлөв",
  dataIndex: "isenable",
  key: "isenable",
  align: "center",
  render: (value) => <Formatter value={value} format="status" />,
};

const InsYmdColumn = {
  title: "Бүртгэсэн огноо",
  dataIndex: "insymd",
  key: "insymd",
  width: 150,
  align: "center",
  render: (value) => <Formatter value={value} format="date" />,
};

const DeleteColumn = {
  title: "Үйлдэл",
  dataIndex: "delete",
  key: "delete",
  width: 65,
  align: "center",
  isExport: false,
  fixed: "right",
  render: (value, record, index) => (
    <Formatter value={value} format="delete" index={index} record={record} />
  ),
  customRender: "delete",
};

export const USER_COLUMNS = [
  NoColumn,
  {
    title: "Хэрэглэгчийн нэр",
    dataIndex: "username",
  },
  {
    title: "Утасны дугаар",
    dataIndex: "phone",
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (value) => <Formatter value={value} format="usertype" />,
  },
  StatusColumn,
  InsYmdColumn,
  {
    title: "Машин бүртгэх",
    dataIndex: "carselect",
    align: "center",
    width: 100,
    render: (value, record) => (
      <Formatter
        value={value}
        record={record}
        format="formmodal"
        formData={{
          title: "main.registercar",
          initialValues: {
            carids: record?.cars?.reduce((prev, curr) => {
              prev.push(curr?.id);
              return prev;
            }, []),
          },
          data: CONCAR_FORM,
        }}
      />
    ),
  },
  {
    title: "Устгах",
    dataIndex: "delete",
    render: (value, record) => (
      <Formatter
        record={record}
        format="delete"
        url="/admin/users/delete"
        method="DELETE"
      />
    ),
  },
];

export const USER_DETAIL_COLUMNS = [
  NoColumn,
  {
    title: "Хэрэглэгчийн нэр",
    dataIndex: "username",
  },
  {
    title: "Утасны дугаар",
    dataIndex: "phone",
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (value) => <Formatter value={value} format="usertype" />,
  },
  StatusColumn,
  InsYmdColumn,
];

export const CAR_DETAIL_COLUMNS = [
  NoColumn,
  {
    title: "Машины дугаар",
    dataIndex: "number",
  },
  {
    title: "Машины нэр",
    dataIndex: "name",
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (value) => <Formatter value={value} format="cartype" />,
  },
  {
    title: "imei",
    dataIndex: "imei",
  },
  {
    title: "Түгжих эрхтэй эсэх",
    dataIndex: "islock",
    render: (value) => <Formatter value={value} format="status" />,
  },
  {
    title: "Оператор",
    dataIndex: "operator",
    render: (value) => <Formatter value={value} format="operator" />,
  },
  StatusColumn,
  InsNameColumn,
  InsYmdColumn,
];

export const CAR_COLUMNS = [
  NoColumn,
  {
    title: "Машины дугаар",
    dataIndex: "number",
  },
  {
    title: "Машины нэр",
    dataIndex: "name",
  },
  {
    title: "Төрөл",
    dataIndex: "type",
    render: (value) => <Formatter value={value} format="cartype" />,
  },
  {
    title: "Модел",
    dataIndex: "model",
  },
  {
    title: "imei",
    dataIndex: "imei",
  },
  {
    title: "Кнопоор асдаг эсэх",
    dataIndex: "iskey",
    render: (value) => <Formatter value={value} format="iskey" />,
  },
  {
    title: "Түгжих эрхтэй эсэх",
    dataIndex: "islock",
    render: (value) => <Formatter value={value} format="status" />,
  },
  {
    title: "Оператор",
    dataIndex: "operator",
    render: (value) => <Formatter value={value} format="operator" />,
  },
  StatusColumn,
  InsNameColumn,
  InsYmdColumn,
  {
    title: "Устгах",
    dataIndex: "delete",
    render: (value, record) => (
      <Formatter
        record={record}
        format="delete"
        url="/admin/car/delete"
        method="DELETE"
      />
    ),
  },
  /*  {
    title: "Хэрэглэгч бүртгэх",
    dataIndex: "userselect",
    align: "center",
    width: 100,
    render: (value, record) => (
      <Formatter
        value={value}
        record={record}
        format="formmodal"
        formData={{
          title: "Хэрэглэгч бүртгэх",
          initialValues: {
            userids: record?.users?.reduce((prev, curr) => {
              prev.push(curr?.id);
              return prev;
            }, []),
          },
          data: CONUSER_FORM,
        }}
      />
    ),
  }, */
];

export const BANNER_COLUMNS = [
  NoColumn,
  {
    title: "Зураг",
    dataIndex: "imgnm",
    width: 100,
    render: (value) => <Formatter value={value} format="img" />,
  },
  {
    title: "Логин зураг",
    dataIndex: "loginimgnm",
    width: 200,
    render: (value) => <Formatter value={value} format="img" />,
  },
  StatusColumn,
  {
    title: "Эхлэх",
    dataIndex: "sdate",
    width: 100,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Дуусах",
    dataIndex: "edate",
    width: 100,
    render: (value) => <Formatter value={value} format="date" />,
  },
  InsYmdColumn,
  {
    title: "Бүртгэсэн хэрэглэгч",
    dataIndex: "insnm",
    key: "insnm",
    align: "right",
    width: 160,
  },
];

export const CATEGORY_COLUMNS = [
  NoColumn,
  {
    title: "Ангилалын нэр",
    dataIndex: "name",
  },
  StatusColumn,
  InsYmdColumn,
  {
    title: "Бүртгэсэн хэрэглэгч",
    dataIndex: "insnm",
    key: "insnm",
    width: 100,
  },
];

export const LICENSE_LIST_COLUMNS = [
  NoColumn,
  {
    title: "Хэрэглэгчийн нэр",
    dataIndex: "username",
  },
  {
    title: "Утасны дугаар",
    dataIndex: "userphone",
  },
  {
    title: "Төлбөр",
    dataIndex: "amount",
    align: "right",
    render: (value, record) => (
      <Formatter value={value} format="price" record={record} />
    ),
  },
  {
    title: "Эхлэх огноо",
    dataIndex: "sdate",
    align: "center",
    render: (value, record) => (
      <Formatter value={value} format="date" record={record} />
    ),
  },
  {
    title: "Дуусах огноо",
    dataIndex: "edate",
    align: "center",
    render: (value, record) => (
      <Formatter value={value} format="date" record={record} />
    ),
  },
  {
    title: "Төлөв",
    dataIndex: "isactive",
    align: "center",
    render: (value) => <Formatter value={value} format="isactive" />,
  },
  InsYmdColumn,
  InsNameColumn,
];

export const PAYMENT_HISTORY_COLUMNS = [
  NoColumn,
  {
    title: "Огноо",
    dataIndex: "transacdate",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Харьцсан данс",
    dataIndex: "transferaccount",
  },
  {
    title: "Дүн",
    dataIndex: "credit",
    align: "center",
    render: (value, record) => (
      <Formatter value={value} format="price" record={record} />
    ),
  },
  {
    title: "Гүйлгээний утга",
    dataIndex: "description",
  },
  {
    title: "Төлөв",
    dataIndex: "issend",
    align: "center",
    render: (value) => <Formatter value={value} format="paymentstatus" />,
  },
  {
    title: "Утасны дугаар",
    dataIndex: "userphone",
  },
  {
    title: "Зассан огноо",
    dataIndex: "senddate",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
  {
    title: "Тэмдэглэл",
    dataIndex: "note",
  },
  {
    title: "Таны данс",
    dataIndex: "accountno",
  },
  InsNameColumn,
  EditColumn,
];

export const FEEDBACK_COLUMNS = [
  NoColumn,
  {
    title: "Төрөл",
    dataIndex: "type",
    width: 150,
    render: (value) => <Formatter value={value} format="feedtype" />,
  },
  {
    title: "Илгээсэн хэрэглэгч",
    dataIndex: "username",
    width: 150,
  },
  {
    title: "Тайлбар",
    dataIndex: "text",
    align: "center",
    width: 60,
    render: (value) => {
      return <span className="block w-48 truncate ">{value}</span>;
    },
  },
  {
    title: "Төлөв",
    dataIndex: "isseen",
    width: 100,
    shouldCellUpdate: () => true,
    render: (value, record) => (
      <Formatter value={value} format="seen" record={record} />
    ),
  },
  {
    title: "Харсан хэрэглэгч",
    dataIndex: "seenbynm",
    width: 200,
  },
  {
    title: "Санал",
    dataIndex: "text",
    align: "center",
    width: 100,
    render: (value, record) => (
      <Formatter value={value} record={record} format="see" />
    ),
  },
  InsYmdColumn,
];

export const ALLCARLOG_COLUMNS = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "carname",
    width: 150,
  },
  {
    title: "Машины дугаар",
    dataIndex: "carnumber",
    width: 150,
  },
  {
    title: "Имэй",
    dataIndex: "carimei",
    width: 150,
  },
  {
    title: "Үйлдэл",
    dataIndex: "cmd",
    width: 50,
    render: (value, record) => (
      <Formatter value={value} format="actions" record={record} />
    ),
  },
  {
    title: "Лог",
    dataIndex: "result",
  },
  {
    title: "Огноо",
    dataIndex: "insymd",
    render: (value) => <Formatter value={value} format="datetime" />,
  },
];

export const NOTIF_COLUMNS = [
  NoColumn,
  {
    title: "Гарчиг",
    dataIndex: "header",
  },
  {
    title: "Мэдэгдэл",
    dataIndex: "note",
  },
  {
    title: "Хэрэглэгч",
    dataIndex: "username",
  },
  {
    title: "Харсан эсэх",
    dataIndex: "isview",
    render: (value, record) => (
      <Formatter value={value} record={record} format="seen" />
    ),
  },
  InsYmdColumn,
  DeleteColumn,
];

export const DEVICE_LIST_COLUMNS = [
  NoColumn,
  {
    title: "Нэр",
    dataIndex: "name",
  },
  {
    title: "Үйлчилгээний цаг",
    dataIndex: "hour",
  },
  {
    title: "Холбоос",
    dataIndex: "link",
  },
  {
    title: "Тайлбар",
    dataIndex: "description",
  },
  StatusColumn,
  InsYmdColumn,
];

export const TIME_LIST_COLUMN = [
  NoColumn,
  {
    title: "Огноо",
    dataIndex: "date",
    width: 100,
    render: (value) => <Formatter value={value} format="date" />,
  },
  {
    title: "Нээх цаг",
    dataIndex: "starthour",
  },
  {
    title: "Хаах цаг",
    dataIndex: "endhour",
  },
  {
    title: "Нэмэлт цаг",
    dataIndex: "taxhour",
  },
  {
    title: "Ажилчны тоо",
    dataIndex: "staffqty",
  },
  {
    title: "Хураамж",
    dataIndex: "amount",
    render: (value) => <Formatter value={value} format="price" />,
  },
  InsYmdColumn,
];

export const ORDER_LIST_COLUMN = [
  NoColumn,
  {
    title: "Захиалгын дугаар",
    dataIndex: "id",
    width: 100,
  },
  {
    title: "Огноо",
    dataIndex: "orderdate",
    width: 150,
    render: (value) => <Formatter value={value} format="datetimenosec" />,
  },
  {
    title: "Утасны дугаар",
    dataIndex: "phone",
  },
  {
    title: "Төхөөрөмж",
    dataIndex: "devicename",
  },
  {
    title: "Машины дугаар",
    dataIndex: "carnumber",
    render: (value, record) => (
      <Formatter value={value} record={record} format="carinfo" />
    ),
  },
  {
    title: "Загвар",
    dataIndex: "carmodel",
  },
  {
    title: "Хураамж",
    dataIndex: "amount",
    render: (value) => <Formatter value={value} format="price" />,
  },
  {
    title: "Төлөв",
    dataIndex: "status",
    render: (value) => <Formatter value={value} format="orderstatus" />,
  },
  {
    title: "Шалтгаан",
    dataIndex: "sms",
    width: 400,
  },

  {
    title: "Төлбөр",
    dataIndex: "ispayd",
    render: (value) => <Formatter value={value} format="ispayd" />,
  },

  InsYmdColumn,
  {
    title: "Төлөв солих",
    dataIndex: "verify",
    render: (value, record) => (
      <Formatter value={value} format="verify" record={record} />
    ),
  },
];

export const VERIFIED_ORDER_LIST_COLUMN = [
  NoColumn,
  {
    title: "Захиалгын дугаар",
    dataIndex: "id",
    width: 100,
  },
  {
    title: "Огноо",
    dataIndex: "orderdate",
    width: 150,
    render: (value) => <Formatter value={value} format="datetimenosec" />,
  },
  {
    title: "Үйлдвэрлэгч",
    dataIndex: "carbrand",
  },
  {
    title: "Загвар",
    dataIndex: "carmodel",
  },
  {
    title: "Бүтээгдэхүүн",
    dataIndex: "devicename",
  },
  {
    title: "Үйлдвэрлэсэн он",
    dataIndex: "carbuildyear",
  },
  {
    title: "Шатахууны төрөл",
    dataIndex: "carfuel",
  },
  {
    title: "Жолооны хүрд",
    dataIndex: "carwheel",
  },
  {
    title: "Машины дугаар",
    dataIndex: "carnumber",
    render: (value, record) => (
      <Formatter value={value} record={record} format="carinfo" />
    ),
  },
  {
    title: "Хэрэглэгчийн дугаар",
    dataIndex: "phone",
  },
  InsYmdColumn,
];
