import { Form, Button, Input, DatePicker, Select, Space, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useEffect, useState } from "react";
import dayjs from "dayjs";
import InfoContainer from "./InfoContainer";
const Index = (props) => {
  const [form] = Form.useForm();
  const [data, setData] = useState([]);
  const [timeList, setTimeList] = useState([]);
  const [deviceList, setDeviceList] = useState([]);
  const [info, setInfo] = useState({ data: null, loading: false });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (props?.open) {
      getCheckDate(dayjs()?.format("YYYY-MM-DD"));
      props?.request({ url: "/order/device/dropdown" })?.then((res) => {
        if (res?.success) setDeviceList(res?.data);
      });
      props?.request({ url: "/order/time/list" })?.then((res) => {
        if (res?.success) setData(res?.data);
      });
    }
  }, []);

  const getCheckDate = (date) => {
    props
      ?.request({
        url: `/order/order/checkdate/${date}`,
      })
      ?.then((res) => {
        if (res.success) {
          setTimeList(res?.data);
        }
      });
  };
  const handleSave = (values) => {
    if (info?.data?.plateNumber) {
      const date = `${dayjs(values?.orderdate)?.format("YYYY-MM-DD")} ${
        values?.ordertime
      }:00:00`;
      const dateTime = dayjs(date, "YYYY-MM-DD HH:mm").tz("UTC", true);
      let findedDevice = deviceList?.find((x) => x.id === values?.deviceid);

      const findedDate = data?.find((x) => {
        const parsedDate = dayjs(x?.date);
        return (
          parsedDate.year() === dateTime?.year() &&
          parsedDate.month() + 1 === dateTime?.month() + 1 &&
          parsedDate.date() === dateTime?.date()
        );
      });
      let body = {
        phone: values?.phone,
        orderdate: dateTime?.toISOString(),
        deviceid: values?.deviceid,
        hour: findedDevice?.hour,
        amount: findedDate?.amount || 0,
        carnumber: info?.data?.plateNumber || "",
        carbrand: info?.data?.markName || "",
        carmodel: info?.data?.modelName || "",
        carcabinnumber: info?.data?.cabinNumber || "",
        carcountry: info?.data?.countryName || "",
        carwheel: info?.data?.wheelPosition || "",
        carfuel: info?.data?.fuelType || "",
        carbuildyear: info?.data?.buildYear?.toString() || "",
      };
      setLoading(true);
      props
        ?.request({ url: `/order/admin`, method: "POST", body: body })
        ?.then((res) => {
          setLoading(false);
          if (res?.success) {
            props?.searchForm?.submit();
            props?.onClose();
            message.success(res?.message);
          } else {
            message.error(res?.message);
          }
        });
    } else {
      message.error("Машины мэдээлэл татагдаагүй байна.");
    }
  };

  const handleChangePhone = (e) => {
    const value = e?.target?.value.replace(/\D/g, "");
    if (value.length <= 8) {
      form?.setFieldValue("phone", value);
    } else {
      form?.setFieldValue("phone", value?.slice(0, -1));
    }
  };

  const handleChangeCarNumber = (e) => {
    let value = e?.target?.value.toUpperCase().replace(/[^0-9А-ЯӨөҮүЁё]/g, "");
    const numbersPart = value.slice(0, 4).replace(/[^0-9]/g, "");
    const lettersPart = value.slice(4).replace(/[^А-ЯӨөҮүЁё]/g, "");
    const formattedValue = (numbersPart + lettersPart).slice(0, 7);
    form?.setFieldValue("carnumber", formattedValue);
  };

  const handleChangeDate = (e) => {
    form?.setFieldValue("ordertime", null);
    getCheckDate(e?.format("YYYY-MM-DD"));
  };

  const handleSearch = () => {
    const number = form?.getFieldValue("carnumber");
    if (number?.length === 7) {
      setInfo({ loading: true, data: null });
      props
        ?.request({
          url: "",
          method: "POST",
          type: "car",
          body: {
            serviceCode: "WS100401_getVehicleInfo",
            customFields: {
              plateNumber: number,
            },
          },
        })
        ?.then((res) => {
          setInfo({ loading: false, data: res });
        });
    } else message.error("Зөв дугаар оруулна уу.");
  };

  return (
    <div>
      <Form
        form={form}
        onFinish={handleSave}
        initialValues={{
          orderdate: dayjs(),
        }}
      >
        <Form.Item
          name="phone"
          rules={[{ required: true, message: "Утасны дугаар оруулна уу" }]}
        >
          <Input placeholder="Утасны дугаар" onChange={handleChangePhone} />
        </Form.Item>
        <Form.Item
          name="orderdate"
          rules={[{ required: true, message: "Захиалгын огноо оруулна уу" }]}
        >
          <DatePicker
            placeholder="Захиалгын огноо"
            className="w-full"
            allowClear={false}
            onChange={handleChangeDate}
          />
        </Form.Item>
        <Form.Item
          name="ordertime"
          rules={[{ required: true, message: "Захиалгын цаг сонгоно уу" }]}
        >
          <Select
            placeholder="Захиалгын цаг"
            options={timeList?.map((item) => ({
              ...item,
              disabled: item?.isdisable,
            }))}
            labelRender={(row) => {
              const finded = timeList?.find((x) => x?.hour === row?.value);
              return (
                <Space className="flex justify-between">
                  <span>{row?.label}</span>
                  <span className="text-xs">{finded?.ordercount}</span>
                </Space>
              );
            }}
            optionRender={(row) => {
              return (
                <Space className="flex justify-between">
                  <span>{row?.label}</span>
                  <span className="text-xs">{row?.data?.ordercount}</span>
                </Space>
              );
            }}
            fieldNames={{ label: "hour", value: "hour" }}
          />
        </Form.Item>
        <Form.Item
          name="deviceid"
          rules={[{ required: true, message: "Төхөөрөмж сонгоно уу" }]}
        >
          <Select
            placeholder="Төхөөрөмж"
            options={deviceList}
            fieldNames={{ label: "name", value: "id" }}
          />
        </Form.Item>
        <Form.Item
          name="carnumber"
          rules={[{ required: true, message: "Машины дугаар оруулна уу" }]}
        >
          <Input
            placeholder="Машины дугаар"
            onChange={handleChangeCarNumber}
            suffix={
              <Button
                type="primary"
                icon={<SearchOutlined />}
                loading={info?.loading}
                size="small"
                onClick={handleSearch}
              />
            }
          />
        </Form.Item>
        <Button className="w-full mt-4" type="primary" htmlType="submit">
          Хадгалах
        </Button>
      </Form>
      {info?.data?.plateNumber ? (
        <InfoContainer data={info?.data || null} />
      ) : null}
    </div>
  );
};

export default Index;
