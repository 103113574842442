import { useEffect } from "react";
import { Layout } from "antd";
import { isShow, islogged } from "../../utils";
import { Sider, Header } from "../../components";
import { Routes, Route, Navigate } from "react-router-dom";
import {
  Home,
  Login,
  Shop,
  License,
  Settings,
  SetupBiometrics,
  Notfound,
  List,
  Register,
  QrPage,
  Alarm,
  CarSettings,
  Allmap,
} from "../";
import { useSocketEvent, useSocketEmit } from "../../socket";
import { MENU } from "../../data";
import { UserType } from "../../enums";
const { Content } = Layout;

const PrivateRoute = ({ context, config, Page }) => {
  if (islogged(context)) return <Page {...context} config={config} />;
  return <Navigate to="/login" />;
};

const Index = (props) => {
  const { join } = useSocketEmit();

  const handleEvent = () => {
    if (props?._auth && props?.islogin) {
      join({ name: "AUTOCAR", roomname: props?._auth?.user?.id });
    }
  };

  const hexToRgba = (hex, alpha) => {
    // Remove the hash at the start if it's there
    hex = hex.replace("#", "");

    // Parse the hex into RGB values
    var r = parseInt(hex.slice(0, 2), 16);
    var g = parseInt(hex.slice(2, 4), 16);
    var b = parseInt(hex.slice(4, 6), 16);

    // Return the RGBA color with specified alpha
    return "rgba(" + r + ", " + g + ", " + b + ", " + alpha + ")";
  };

  useEffect(() => {
    document.documentElement.style.setProperty("--color-primary", props?.color);
    document.documentElement.style.setProperty(
      "--color-primaryopa",
      hexToRgba(props?.color, 0.3)
    );
  }, [props?.color]);

  useEffect(() => {
    if (props?._auth && props?.islogin) {
      props?.request({
        url: `/user/banners`,
        model: "banners",
      });
      props?.request({
        url: `/order/config`,
        model: "adminconfig",
      });
      props?.request({ url: "/user/ourdata", model: "userdata" });
      props.request({
        url: `/user/cars`,
        model: "carsinfo",
        method: "POST",
      });

      if (
        props?._auth?.user?.type === UserType.Админ ||
        props?._auth?.user?.type === UserType.Супер_админ
      ) {
        props?.request({
          url: `/admin/userspopup`,
          model: "userspopup",
          method: "POST",
        });
        props?.request({
          url: `/admin/carlistpopup`,
          model: "carspopup",
          method: "POST",
        });
      }
    }
  }, [props._auth]);

  useEffect(() => {
    props?.request({
      url: "/admin/loginbanner",
      method: "POST",
      model: "loginbanner",
    });
  }, []);

  useSocketEvent("connect", handleEvent);

  const renderChildRoutes = () => {
    let tmp = [];
    MENU?.map((item) => {
      item?.childs?.map((child, i) => {
        if (child?.role?.find((y) => y === props?._auth?.user?.type)) {
          tmp.push(
            <Route
              key={i}
              path={child.route}
              element={
                <PrivateRoute
                  context={props}
                  config={child?.config}
                  Page={List}
                />
              }
            />
          );
        }
      });
    });
    return tmp;
  };

  return (
    <Content className="h-full bg-light_back dark:bg-dark p-2 sm:p-2 md:p-8 transition-all">
      <div className="flex w-full h-full text-black dark:text-white gap-2 sm:gap-2 md:gap-8 relative">
        {isShow(props) && <Sider {...props} />}
        <div className="flex flex-col w-full gap-2 sm:gap-2 md:gap-8">
          {isShow(props) && <Header {...props} />}
          <div
            className={`overflow-y-auto h-full relative ${
              isShow(props) ? "mb-20" : "mb-0"
            } md:mb-0`}
          >
            <Routes className="w-full h-full">
              <Route
                path="/"
                element={<PrivateRoute context={props} Page={Home} />}
              />
              <Route
                path="/shop"
                element={<PrivateRoute context={props} Page={Shop} />}
              />
              <Route
                path="/License"
                element={<PrivateRoute context={props} Page={License} />}
              />
              <Route
                path="/settings"
                element={<PrivateRoute context={props} Page={Settings} />}
              />
              <Route
                path="/allmap"
                element={<PrivateRoute context={props} Page={Allmap} />}
              />
              <Route
                path="/biometrics"
                element={
                  <PrivateRoute context={props} Page={SetupBiometrics} />
                }
              />
              {/* <Route
                path="/notification"
                element={<PrivateRoute context={props} Page={Notification} />}
              /> */}

              <Route
                path="/carssettings"
                element={<PrivateRoute context={props} Page={CarSettings} />}
              />
              <Route
                path="/alarm"
                element={<PrivateRoute context={props} Page={Alarm} />}
              />
              <Route path="/qrpage" element={<QrPage {...props} />} />
              {renderChildRoutes()}
              <Route path="/login" element={<Login {...props} />} />
              <Route path="/register/*" element={<Register {...props} />} />
              <Route path="*" element={<Notfound {...props} />} />
            </Routes>
          </div>
        </div>
      </div>
    </Content>
  );
};

export default Index;
