import React, { createContext, useContext, useEffect, useMemo } from "react";
import { io } from "socket.io-client";
// Socket context
export const SocketContext = createContext(null);

export const useSocket = () => {
  return useContext(SocketContext);
};

// Socket рүү шидэх
export const useSocketEmit = () => {
  const socket = useSocket();

  const emit = (event, ...args) => {
    if (socket)
      socket.emit(event, ...args, (error) => console.log(`Socket: ${error}`));
    else console.log("Error socket emit");
  };

  const join = (...args) => {
    if (socket)
      socket.emit("join", ...args, (error) => console.log(`Socket: ${error}`));
    else console.log("Error socket join");
  };

  return { emit, join };
};

// Socket с хүлээн авах
export const useSocketEvent = (event, handler) => {
  const socket = useSocket();
  useEffect(() => {
    if (socket) socket.on(event, handler);
    else console.log("Socket not initialize");

    return () => {
      if (socket) socket.off(event, handler);
    };
  }, [socket, event, handler]);
};

// Socket Provider
export const SocketProvider = ({ url, options, isConnect, children }) => {
  const socket = useMemo(() => {
    if (isConnect) return io(url, options);
    return null;
  }, [url, options, isConnect]);

  // isConnect === true үед сервертэй холболт үүсгэнэ
  useEffect(() => {
    if (socket) {
      if (isConnect) {
        console.log("connected");
        socket.connect();
      } else socket.disconnect();
    }

    return () => {
      if (socket) socket.disconnect();
    };
  }, [isConnect, socket]);

  return (
    <SocketContext.Provider value={socket}>{children}</SocketContext.Provider>
  );
};
