import { useState } from "react";
import { Button, Form, Tooltip } from "antd";
import { URLS } from "../../../utils";
import { PlusOutlined } from "@ant-design/icons";
import { Modal, Form as CForm } from "../..";
import { ADD_PRODUCT_FORM } from "../../../data/forms";
import { IsEnable, UserType } from "../../../enums";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = Form.useForm();
  const [open, setOpen] = useState(false);

  const handleOpen = () => setOpen(true);

  const onClose = () => {
    setOpen(false);
    form?.resetFields();
  };

  const handleEdit = () => {
    if (props?._auth?.user?.type !== UserType.Хэрэглэгч) {
      form.setFieldsValue({
        ...props?.product,
        img: props?.product?.imgnm,
      });
      handleOpen();
    }
  };

  const handleSave = (values) => {
    setLoading(true);
    props
      ?.request({
        url: `/product/add`,
        method: "POST",
        body: { ...values, id: values?.id ? values?.id : 0 },
        isfile: true,
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        form?.resetFields();
        if (res.success) {
          props?.request({
            url: `/product/get/${props?.active}`,
            model: "product",
          });
          setOpen(false);
        }
      });
  };

  const getContent = () => {
    if (props?.empty) {
      return (
        <Button
          type="dashed"
          onClick={handleOpen}
          className="h-64 w-full flex items-center justify-center flex-col"
        >
          <PlusOutlined />
          <div
            style={{
              marginTop: 8,
            }}
          >
            {props?.translate("main.addproduct")}
          </div>
        </Button>
      );
    }
    let tmp = (
      <Tooltip title={props?.product?.description}>
        <Button
          onClick={handleEdit}
          className="h-64 relative flex w-full max-w-xs flex-col overflow-hidden rounded-lg border  border-gray-100 dark:border-border bg-white dark:bg-black shadow-md"
        >
          <div className="relative mt-3 flex h-40 overflow-hidden rounded-xl justify-center w-full">
            <img
              className="object-cover"
              src={`${URLS.image}/${props?.product?.imgnm}`}
              alt="product image"
            />
          </div>
          <div className="mt-4  flex flex-col items-start">
            <h5 className="text-lg tracking-tight text-slate-900 dark:text-white">
              {props?.product?.name}
            </h5>
            <div className=" flex items-center justify-between">
              <span className="text-lg font-bold text-slate-900 dark:text-white">
                {new Intl.NumberFormat().format(props?.product?.price || 0)}
              </span>
            </div>
          </div>
        </Button>
      </Tooltip>
    );
    if (
      props?._auth?.user?.type === UserType.Хэрэглэгч &&
      props?.product?.isenable === IsEnable.Идэвхтэй
    ) {
      return tmp;
    }
    if (props?._auth?.user?.type !== UserType.Хэрэглэгч) return tmp;

    return undefined;
  };
  const content = getContent();
  if (!content) return null;
  return (
    <div>
      {content}
      <Modal
        title={props?.translate("main.addproduct")}
        open={open}
        onClose={onClose}
        width={750}
        isShowFooter={false}
        height={"60%"}
      >
        <CForm
          form={form}
          data={ADD_PRODUCT_FORM}
          handleSave={handleSave}
          loading={loading}
          className="mt-2"
          btnTitle="main.save"
          {...props}
        />
      </Modal>
    </div>
  );
};

export default Index;
