import { Table } from "antd";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ExportTable } from "../";
const Index = (props) => {
  const [selected, setSelected] = useState(null);
  const location = useLocation();

  useEffect(() => {
    if (props.editRef) props.editRef.current = selected;
  }, [selected]);

  useEffect(() => {
    setSelected(null);
  }, [location?.pathname]);

  useEffect(() => {
    if (!props?.open && selected) setSelected(null);
  }, [props?.open]);

  const dataSource = props?.dataSource
    ? props?.dataSource
    : props[`res${props?.config?.model}`] || [];

  const columns = props?.columns ? props?.columns : props?.config?.columns;
  const getWindowname = () => {
    if (props?.addform?.title)
      return props?.addform?.title?.replace("бүртгэх", "жагсаалт");
    return "Жагсаалт";
  };
  return (
    <div>
      <Table
        size="small"
        scroll={{ x: "auto" }}
        className="mt-2"
        loading={props[`is${props?.config?.model}`]}
        rowClassName={(record) => {
          if (record?.statementid) {
            if (selected && record?.statementid === selected?.statementid) {
              return `bg-activeopa  transition-all`;
            }
          } else if (selected && record?.id === selected?.id) {
            return `bg-activeopa  transition-all`;
          }

          return "";
        }}
        dataSource={dataSource}
        columns={columns}
        rowKey={(row) => row.id}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              props?.config?.doubleClick &&
                props?.search &&
                props?.search(record);
            },
            onClick: () => {
              setSelected(record);
            },
          };
        }}
      />
      <div className="hidden">
        <ExportTable
          name={getWindowname()}
          dataSource={dataSource}
          columns={columns}
          ref={props?.tableRef}
          exportRef={props?.exportRef}
        />
      </div>
    </div>
  );
};

export default Index;
