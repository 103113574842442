export default function appReducer(state, action) {
  const type = action.type.split("_")[0];
  const model = action.type.split("_")[1];
  switch (type) {
    case "request": {
      if (model !== "carsinfo") state[`res${model}`] = [];

      state[`is${model}`] = true;
      return { ...state };
    }

    case "error": {
      state[action.type] = action.error;
      state[`err_${model}`] = false;
      return { ...state };
    }

    case "response": {
      if (model === "licenseinfo" && action.response?.success)
        state[`res${model}`] = action.response?.success;
      else if (model === "reload")
        state[`res${model}`] = action.response?.success;
      else state[`res${model}`] = action.response.data;
      state[`is${model}`] = false;
      return { ...state };
    }

    case "setdata": {
      state[`res${model}`] = action.response;
      return { ...state };
    }
    case "mobiledata": {
      return { ...state, mobiledata: action.response };
    }

    case "login": {
      return {
        ...state,
        lang: "mn",
        islogin: action.response,
        _auth: action.response
          ? JSON.parse(localStorage.getItem("auth"))
          : undefined,
      };
    }
    case "auth": {
      return {
        ...state,
        _auth: action.response,
      };
    }
    case "color": {
      return {
        ...state,
        color: action.response,
      };
    }
    case "lang": {
      return {
        ...state,
        lang: action.response,
      };
    }
    case "theme": {
      return {
        ...state,
        theme: action.response,
      };
    }

    default:
      return state;
  }
}
