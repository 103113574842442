import React from "react";
import dayjs from "dayjs";
import ReactHTMLTableToExcel from "react-html-table-to-excel";

const Index = React.forwardRef((props, ref) => {
  const { dataSource, columns, name, exportRef } = props;

  const tdStyle = { paddingRight: "3px", paddingLeft: "3px" };
  const formatStyle = (item, col, i) => {
    if (col.title === "№") return i + 1;
    else if (col.exportRender) return col.exportRender(item[col.dataIndex]);
    else if (col.render)
      return col.render(
        item[col.dataIndex] ? item[col.dataIndex] : "",
        item,
        i
      );
    return item[col.dataIndex];
  };

  const renderRow = (data) => {
    if (data) {
      return data.map((item, i) => (
        <tr key={i}>
          {columns &&
            columns.map((col, i1) =>
              col.isExport === false ? null : (
                <td style={{ ...tdStyle, textAlign: col.align }} key={i1}>
                  {formatStyle(item, col, i)}
                </td>
              )
            )}
        </tr>
      ));
    }
  };

  return (
    <div ref={ref}>
      <ReactHTMLTableToExcel
        id="test-table-xls-button"
        table="table-to-xls"
        filename={name}
        sheet="Жагсаалт"
        buttonText="Татах"
        ref={exportRef}
      />
      <div className="flex justify-between items-center">
        <span className="text-xl font-bold">{name}</span>
        <span>Хэвлэсэн огноо: {dayjs().format("YYYY-MM-DD")}</span>
      </div>
      <table className="my-5 w-full" id="table-to-xls">
        <thead>
          <tr>
            {columns &&
              columns.map((item, i) =>
                item.isExport === false ? null : (
                  <th key={i} style={{ ...tdStyle, textAlign: "center" }}>
                    {item.title}
                  </th>
                )
              )}
          </tr>
        </thead>
        <tbody>{renderRow(dataSource)}</tbody>
      </table>
      <span className="text-grey">Хэвлэсэн: Onis Service</span>
    </div>
  );
});

export default Index;
