import { useState, useEffect } from "react";
import { SET_ADMIN_NUMBER } from "../../../data/forms";
import { isMobile } from "react-device-detect";
import { Form as AntdForm, Input, message, Drawer } from "antd";
import { Popover, Button } from "antd";
import { Form } from "../..";
import { MessageOutlined } from "@ant-design/icons";
import { Cards } from "../..";
const Index = (props) => {
  const [isOpenNotif, setIsOpenNotif] = useState(false);
  const [data, setData] = useState([]);

  useEffect(() => {
    if (isOpenNotif) {
      reload();
    }
  }, [isOpenNotif, props?.selected]);

  const reload = () => {
    props
      ?.request({
        url: `/user/carlog/message/${props?.selected?.id}`,
        method: "POST",
      })
      ?.then((res) => {
        if (res.success) {
          setData(res?.data);
        }
      });
  };

  const onCloseNotif = () => setIsOpenNotif(false);

  return (
    <div>
      <Button
        onClick={() => setIsOpenNotif(true)}
        icon={<MessageOutlined />}
        className="flex items-center w-full font-semibold  mt-2"
      >
        {props?.translate("main.messagelog")}
      </Button>
      <Drawer
        title={props?.translate("main.messagelog")}
        placement="right"
        width={isMobile ? "80%" : 500}
        onClose={onCloseNotif}
        open={isOpenNotif}
      >
        <div className="flex flex-col py-2 px-5 w-full gap-2">
          {data?.length ? (
            data?.map((item, i) => (
              <Cards.NotificationCard
                key={i}
                data={item}
                request={props?.request}
                isLog={true}
                reload={reload}
              />
            ))
          ) : (
            <div className="flex items-center justify-center py-10 ">
              <span className="text-border">
                {props?.translate("main.notifdesc")}
              </span>
            </div>
          )}
        </div>
      </Drawer>
    </div>
  );
};

export default Index;
