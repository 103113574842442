import { Form } from "..";
import dayjs from "dayjs";
import { ISENABLE, ORDERSTATUS, SUCCESSORFAIL } from "../../data/forms";
import { useEffect, useState } from "react";
import { Button, Input } from "antd";
import { FileExcelOutlined } from "@ant-design/icons";
import { useLocation } from "react-router-dom";
import { Modal } from "..";
const Index = (props) => {
  const [isOpen, setIsOpen] = useState(false);
  const [note, setNote] = useState("");
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const handleSearch = (values) => {
    if (props?.config?.url && props?.config?.method && props?.config?.model) {
      if (values?.sdate) {
        const date = `${dayjs(values?.sdate)?.format("YYYY-MM-DD")} 00:00:00`;
        const dateTime = dayjs(date, "YYYY-MM-DD HH:mm").tz("UTC", true);
        values.sdate = dateTime?.toISOString();
      }
      if (values?.edate) {
        const date = `${dayjs(values?.edate)?.format("YYYY-MM-DD")} 00:00:00`;
        const dateTime = dayjs(date, "YYYY-MM-DD HH:mm").tz("UTC", true);
        values.edate = dateTime?.toISOString();
      }
      if (location?.pathname === "/list/verifiedorders") {
        values.status = 1;
      }
      props.request({
        url: props?.config?.url,
        model: props?.config?.model,
        method: props?.config?.method,
        body: values,
      });
    }
  };

  useEffect(() => {
    if (isOpen) {
      if (props?.resadminconfig) {
        setNote(props?.resadminconfig?.text || "");
      }
    }
  }, [isOpen]);

  useEffect(() => {
    if (props?.resreload === true) {
      props?.form?.submit();
    }
  }, [props?.resreload]);

  const getControl = (name) => {
    switch (name) {
      case "name":
        return {
          type: "input",
          placeholder: props?.translate("control.name"),
          name: name,
        };
      case "phone":
        return {
          type: "input",
          placeholder: props?.translate("control.phoneno"),
          name: name,
        };
      case "carnumber":
        return {
          type: "input",
          placeholder: props?.translate("control.carnumber"),
          name: name,
        };
      case "carname":
        return {
          type: "input",
          placeholder: props?.translate("control.carname"),
          name: name,
        };
      case "sdate":
        return {
          type: "date",
          placeholder: props?.translate("control.sdate"),
          name: name,
        };
      case "edate":
        return {
          type: "date",
          placeholder: props?.translate("control.edate"),
          name: name,
        };
      case "isenable":
        return {
          type: "select",
          placeholder: props?.translate("control.status"),
          options: ISENABLE,
          name: name,
        };
      case "status":
        return {
          type: "select",
          placeholder: props?.translate("control.status"),
          options: ORDERSTATUS,
          name: name,
        };
      case "isactive":
        return {
          type: "select",
          placeholder: props?.translate("control.status"),
          options: SUCCESSORFAIL,
          name: name,
        };
      case "userid":
        return {
          type: "select",
          placeholder: props?.translate("control.user"),
          options: "userspopup",
          fieldNames: {
            label: "username",
            value: "id",
          },
          name: name,
        };
      default:
        return {
          type: "input",
          placeholder: props?.translate("control.phoneno"),
          name: name,
        };
    }
  };

  const getSearchControl = () => {
    if (props?.config?.search) {
      const searchControls = props?.config?.search.split(",");
      if (searchControls && searchControls.length) {
        let tmp = searchControls.map((item) => getControl(item));
        return tmp;
      }
    }
    return [];
  };

  const handleNew = () => {
    if (props?.handleNew && props?.config?.addform) props?.handleNew();
  };

  const handleEdit = () => {
    if (props?.handleEdit && props?.config?.addform) props?.handleEdit();
  };

  const handleExport = () => {
    props?.exportRef?.current?.handleDownload?.();
  };

  const handleSaveNote = () => {
    props
      ?.request({
        url: "/order/admin/config",
        ismessage: true,
        method: "POST",
        body: {
          text: note,
        },
      })
      ?.then((res) => {
        setLoading(false);
        if (res.success) {
          setIsOpen(false);
          props?.request({
            url: `/order/config`,
            model: "adminconfig",
          });
        }
      });
  };

  return (
    <div className="flex w-full justify-between">
      <div className="w-full flex">
        <Form
          className="w-full"
          // layout="inline"
          name="search"
          form={props?.form}
          btnTitle={props?.translate("main.search")}
          data={getSearchControl()}
          handleSave={handleSearch}
          loading={props?.config?.loading === true}
          handleNew={handleNew}
          handleEdit={handleEdit}
          isAddForm={props?.config?.addform}
          {...props}
        />
      </div>
      <div className="flex gap-2">
        <Button onClick={() => setIsOpen(true)}>Тайлбар</Button>
        <Button
          className="hidden lg:block"
          icon={<FileExcelOutlined />}
          onClick={handleExport}
        />
      </div>
      <Modal
        title={"Тайлбар бүртгэх"}
        open={isOpen}
        onClose={() => setIsOpen(false)}
        width={300}
        isShowFooter={false}
        height={"80%"}
      >
        <div>
          <Input.TextArea
            placeholder="Тайлбар"
            value={note}
            onChange={(e) => setNote(e?.target?.value)}
          />
          <Button
            className="w-full mt-4"
            type="primary"
            loading={loading}
            onClick={handleSaveNote}
          >
            Хадгалах
          </Button>
        </div>
      </Modal>
    </div>
  );
};

export default Index;
