import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { LOGIN_FORM } from "../../data/forms";
import { Form } from "../../components";
import logo from "../../assets/logos/logo.png";
import { saveLoginData, useMobileEvent, postMessageMobile } from "../../utils";
import { message, Form as AntdForm } from "antd";
import { URLS } from "../../utils";
import { ADDRESS, PHONE } from "../../data";
const Index = (props) => {
  const { mobiledata } = props;
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [loginForm] = AntdForm.useForm();

  const handleMobileRequest = (e) => {
    try {
      let data = JSON.parse(e.data);
      if (data) {
        if (data.type === "LOGIN_SUCCESS") {
          handleSave({
            name: data?.data?.name,
            pass: data?.data?.pass,
            checkbox: true,
            isbio: true,
          });
        } else if (data.type === "LOGIN_ERROR") {
          message.error(data.msg);
        } else if (data?.type === "CHECK_SUCCESS") {
          if (data?.data?.success === true) {
            navigate("/");
          } else {
            localStorage.setItem("biometric", JSON.stringify(data?.data));
            navigate("/biometrics");
          }
        }
      }
    } catch (e) {}
  };

  useMobileEvent(handleMobileRequest);

  const loginDemo = () => {
    handleSave({ name: "demo", pass: "admin123", checkbox: false });
  };

  const handleSave = (values) => {
    setLoading(true);
    if (mobiledata && mobiledata?.token) values.notfkey = mobiledata?.token;
    props
      .request({
        url: `/auth/userlogin`,
        method: "POST",
        body: values,
        ismessage: true,
      })
      .then((res) => {
        setLoading(false);
        if (res?.success) {
          saveLoginData({ values, data: res?.data });
          props.setlogin(true);
          if (props?.mobiledata && props?.mobiledata?.bioType !== "NOT") {
            postMessageMobile({ type: "CHECK_CREDENTIAL", data: values });
          } else {
            navigate("/");
          }
          /* saveLoginData({ values, data: res?.data });
          props.setlogin(true);
          if (
            props?.mobiledata &&
            props?.mobiledata.bioType &&
            props?.mobiledata.bioType !== "NOT" &&
          ) {
            localStorage.setItem("biometric", JSON.stringify(values));
            navigate("/biometrics");
          } else {
            navigate("/");
          } */
        }
      });
  };

  const fingerClicked = () => {
    postMessageMobile({ type: "FINGER_CLICKED", data: "" });
  };

  const forgotPassword = () => {
    navigate("/register/forgetpass");
  };

  const signUp = () => {
    navigate("/register/maininfo");
  };

  return (
    <div className="bg-white dark:bg-gray-900 h-full relative">
      <div className="flex justify-center h-full ">
        <div
          className="hidden bg-cover bg-no-repeat lg:block lg:w-3/6 "
          style={{
            backgroundImage: `url(${
              URLS.image
            }/${props?.resloginbanner?.loginimgnm?.replace(/\\/g, "/")})`,
          }}
        >
          <div className="flex items-center h-full px-20 bg-gray-900 bg-opacity-40 rounded-lg">
            <div>
              <h2 className="text-4xl font-bold text-white">Automotive</h2>

              <p className="max-w-xl mt-3 text-gray-300">
                Та өөрийн автомашинаа хаанаас ч удирдах боломжтой.
              </p>
            </div>
          </div>
        </div>

        <div className="flex items-center w-full max-w-md px-6 mx-auto lg:w-2/6 bg-white dark:bg-black relative h-full">
          <div className="w-full h-full flex flex-col justify-center">
            <div className="text-center ">
              <h2 className="text-4xl font-bold text-center text-gray-700 dark:text-white">
                Нэвтрэх
              </h2>

              <p className="mt-3 text-gray-500 dark:text-gray-300">
                Та бүртгэлтэй эрхээрээ нэвтрэнэ үү
              </p>
            </div>

            <div className="mt-8">
              <Form
                data={LOGIN_FORM}
                form={loginForm}
                handleSave={handleSave}
                loading={loading}
                initialValues={{
                  name: localStorage.getItem("name"),
                  checkbox: localStorage.getItem("name") ? true : false,
                }}
                className="mt-2"
                size="large"
                fingerClicked={fingerClicked}
                btnTitle="main.login"
                {...props}
              />

              <p className="mt-6 text-sm text-gray-400">
                <div
                  onClick={signUp}
                  className="text-blue-500 focus:outline-none focus:underline hover:underline cursor-pointer"
                >
                  Бүртгүүлэх
                </div>
              </p>
              <div className="flex mt-4">
                <div
                  onClick={forgotPassword}
                  className="cursor-pointer text-sm text-center text-blue-500 hover:underline"
                >
                  Нууц үг мартсан?
                </div>
              </div>
              <div className="flex mt-4">
                <div
                  onClick={loginDemo}
                  className="cursor-pointer text-sm text-center text-blue-500 hover:underline"
                >
                  Туршилтын хэрэглэгчээр нэвтрэх
                </div>
              </div>
            </div>
            <div className="mt-10 flex flex-col text-sm items-center w-full">
              <span className="text-xs w-full block">{ADDRESS}</span>
              <span className="text-xs w-full block">{PHONE}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Index;
