import { Button } from "antd";

const Index = (props) => {
  return (
    <Button
      className={`flex items-center justify-center ${
        props?.active ? "bg-white" : "bg-card"
      }`}
      shape="circle"
      onClick={() =>
        props?.onClick ? props?.onClick(props?.value) : undefined
      }
    >
      <img
        src={props?.icon}
        alt={props?.value}
        className={`w-5 ${props?.active ? "active-theme-icon" : "theme-icon"}`}
      />
    </Button>
  );
};

export default Index;
