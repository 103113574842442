import React, { useEffect, useRef } from "react";
import { Html5Qrcode } from "html5-qrcode";

function QRCodeScanner({ onScanSuccess, onScanFailure, open }) {
  useEffect(() => {
    const html5QrCode = new Html5Qrcode("qr-code-reader");

    if (open) {
      html5QrCode.start(
        { facingMode: "environment" },
        { fps: 10, qrbox: 250 },
        onScanSuccess,
        onScanFailure
      );
    }

    return () => {
      if (open) {
        html5QrCode.stop().catch((error) => {
          console.error("Error stopping the QR scanner:", error);
        });
      }
    };
  }, [open, onScanSuccess, onScanFailure]);

  return <div id="qr-code-reader" />;
}

export default QRCodeScanner;
