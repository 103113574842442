import iTire from "../../assets/icons/iTire.png";

const Index = (props) => {
  return (
    <div className="h-full flex flex-col items-center justify-center">
      <div className="flex items-center justify-center rounded-xl  flex-col p-5 shadow-lg">
        <div className="flex items-center">
          <span className="text-8xl mr-2 font-bold">4</span>
          <img className="w-20 h-20 object-contain" src={iTire} alt="tire" />
          <span className="text-8xl mr-2 font-bold">4</span>
        </div>
        <div className="mt-5">
          <span className="text-2xl text-active">
            {props?.translate("main.notfound")}
          </span>
        </div>
      </div>
    </div>
  );
};

export default Index;
