import { memo, useState, useEffect } from "react";
import dayjs from "dayjs";

const Index = (props) => {
  const [time, setTime] = useState(null);

  useEffect(() => {
    const interval = setInterval(() => {
      setTime(dayjs());
    }, 1000);

    return () => clearInterval(interval);
  }, []);

  return (
    <div className="flex flex-col w-48">
      <span className="font-bold text-3xl">{time?.format("HH:mm")}</span>
      <span className="text-xs">
        {time?.locale(props?.lang)?.format("dddd | D MMM YYYY")}
      </span>
    </div>
  );
};

export default memo(Index, (next, prev) => next?.lang === prev?.lang);
