import { useState, useEffect } from "react";
import { SET_START_TIME, SET_USER_LOCATION } from "../../../data/forms";
import { Form as AntdForm } from "antd";
import { Popover, Button } from "antd";
import { Form } from "../..";
import { CarOutlined, FieldTimeOutlined } from "@ant-design/icons";
import iLocation from "../../../assets/icons/iLocation.png";
const Index = (props) => {
  const [loading, setLoading] = useState(false);
  const [form] = AntdForm.useForm();

  const saveLocation = (values) => {
    setLoading(true);
    props
      ?.request({
        url: `/user/car/powertime/${values?.carid}/${values?.minute}`,
        method: "POST",
        ismessage: true,
      })
      .then((res) => {
        props.request({
          url: `/user/cars`,
          model: "carsinfo",
          method: "POST",
        });
        setLoading(false);
      });
  };

  useEffect(() => {
    if (props?.selected) {
      form?.setFieldValue("minute", props?.selected?.powermin);
      form?.setFieldValue("carid", props?.selected?.id);
    }
  }, [props?.selected]);

  return (
    <Popover
      trigger="click"
      content={
        <div className="">
          <Form
            {...props}
            form={form}
            loading={loading}
            handleSave={saveLocation}
            className="mt-2"
            initialValues={{ carid: props?.selected?.id }}
            data={SET_START_TIME}
          />
        </div>
      }
      title={props?.translate("main.setstarttime")}
    >
      <Button
        // icon={<img src={iLocation} alt="iLocation" className="h-5 icon" />}
        icon={<FieldTimeOutlined />}
        /*  type="link" */
        className="flex items-center w-full font-semibold"
      >
        {props?.translate("main.setstarttime")}
      </Button>
    </Popover>
  );
};

export default Index;
