import { MapContainer, TileLayer, Marker, Popup } from "react-leaflet";

import { SearchOutlined } from "@ant-design/icons";
import { Button, Form, Input } from "antd";

const Index = (props) => {
  const [form] = Form.useForm();

  const handleSearch = (values) => {
    props?.request({
      url: `/sock/location/list`,
      method: "POST",
      body: values,
      model: "allmap",
    });
  };

  return (
    <div className={`w-full h-full flex flex-col`}>
      <Form form={form} layout="inline" onFinish={handleSearch}>
        <Form.Item name="number">
          <Input placeholder="Машины дугаараар хайх" allowClear />
        </Form.Item>
        <Button
          icon={<SearchOutlined />}
          htmlType="submit"
          type="primary"
          loading={props?.isallmap === true}
        >
          Хайх
        </Button>
      </Form>
      <div className="relative w-full h-full">
        <MapContainer center={[47.92, 106.92]} zoom={16} scrollWheelZoom={true}>
          <TileLayer
            url="https://{s}.google.com/vt/lyrs=s&x={x}&y={y}&z={z}"
            subdomains={["mt0", "mt1", "mt2", "mt3"]}
          />
          {props?.resallmap?.map((item, i) => {
            if (item?.lat && item?.lon) {
              return (
                <Marker position={[Number(item?.lat), Number(item?.lon)]}>
                  <Popup className="text-black">
                    {item?.name} <br /> {item?.number}
                  </Popup>
                </Marker>
              );
            }
            return null;
          })}
        </MapContainer>
      </div>
    </div>
  );
};
export default Index;
