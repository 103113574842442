import { URLS } from "../../utils";
import { memo } from "react";
import { Carousel } from "antd";
const Index = (props) => {
  return (
    <Carousel
      autoplay
      swipeToSlide
      draggable
      className="h-full w-full banner-car"
    >
      {props?.data?.map((item, i) => (
        <div
          className="w-full h-full rounded-2xl relative flex items-center justify-center"
          key={i}
        >
          <img
            src={`${URLS.image}/${item?.imgnm}`}
            alt="iMap"
            className="absolute h-full rounded-2xl object-contain"
          />
        </div>
      ))}
    </Carousel>
  );
};
export default Index;
