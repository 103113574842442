import { useState, useEffect } from "react";
import { Button, Spin, Empty } from "antd";
import { Cards } from "../../components";
import { UserType } from "../../enums";
const Index = (props) => {
  const [active, setActive] = useState(null);

  useEffect(() => {
    props.request({ url: "/product/getcategory", model: "categories" });
  }, []);

  useEffect(() => {
    if (props?.rescategories && props?.rescategories.length) {
      if (props?.rescategories[0]?.id !== active)
        setActive(props?.rescategories[0]?.id);
    }
  }, [props?.rescategories]);

  useEffect(() => {
    if (active)
      props.request({ url: `/product/get/${active}`, model: "product" });
  }, [active]);

  return (
    <div className="w-full h-full flex flex-col">
      <span className="font-semibold text-xl">
        {props?.translate("sider.category")}
      </span>
      <div className="py-2 flex gap-1 border-b border-light_grey dark:border-border overflow-x-auto overflow-y-hidden">
        {props?.rescategories?.map((item, i) => (
          <Button
            key={i}
            className={`h-10 flex items-center justify-center shadow-md border border-light_grey dark:border-border py-3 ${
              item.id === active ? "bg-active" : "bg-white dark:bg-card"
            }`}
            onClick={() => setActive(item?.id)}
          >
            {item.name}
          </Button>
        ))}
      </div>
      <div className="h-full mt-2 relative product-container">
        <Spin spinning={props?.isproduct === true}>
          <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-7 gap-2 absolute overflow-y-auto w-full h-full">
            {props?.resproduct?.map((item, i) => (
              <Cards.ProductCard
                {...props}
                product={item}
                key={i}
                active={active}
              />
            ))}
            {props?._auth?.user?.type !== UserType.Хэрэглэгч ? (
              <Cards.ProductCard
                {...props}
                empty={true}
                active={active}
                translate={props?.translate}
              />
            ) : null}
          </div>
        </Spin>
      </div>
    </div>
  );
};

export default Index;
