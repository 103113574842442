import { Popover, Button } from "antd";
import { COLORS } from "../../../data";
const Index = (props) => {
  const changeColor = (item) => {
    localStorage.setItem("color", item);
    props?.setColor(item);
  };

  return (
    <Popover
      trigger="click"
      content={
        <div>
          <div className="grid grid-cols-5 gap-3">
            {COLORS?.map((item, i) => (
              <Button
                onClick={() => changeColor(item)}
                key={i}
                className="rounded-full h-5 w-5"
                style={{ background: item }}
              />
            ))}
          </div>
          {/*   <Button className="w-full mt-2" type="primary">
            {props?.translate("main.colorbtn")}
          </Button> */}
        </div>
      }
      title={props?.translate("main.changecolor")}
    >
      <Button className="rounded-md p-2 mt-2 w-full flex items-center border">
        <div
          className="rounded-full h-5 w-5"
          style={{ background: props?.color }}
        />
        <span className="ml-2 font-semibold">
          {props?.translate("main.changecolor")}
        </span>
      </Button>
    </Popover>
  );
};

export default Index;
