import dayjs from "dayjs";
const Index = (props) => {
  const { data } = props;
  return (
    <div className="w-full mt-4 grid grid-cols-1 lg:grid-cols-2 gap-2">
      <div className="bg-infobg p-3 rounded-md flex flex-col gap-2 shadow shadow-lg">
        {/* <div className="flex items-center justify-between">
          <span>Ангилал</span>
          <span className="font-bold">{data?.className}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Хөдөлгүүрийн багтаамж</span>
          <span className="font-bold">{data?.capacity}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Араа</span>
          <span className="font-bold">{"-"}</span>
        </div> */}
        <div className="flex items-center justify-between">
          <span>Шатахууны төрөл</span>
          <span className="font-bold">{data?.fuelType}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Жолооны хүрд</span>
          <span className="font-bold">{data?.wheelPosition}</span>
        </div>
        {/* <div className="flex items-center justify-between">
          <span>Суудлын тоо</span>
          <span className="font-bold">{data?.manCount}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Өнгө</span>
          <span className="font-bold">{data?.colorName}</span>
        </div> */}
      </div>
      <div className="bg-infobg p-3 rounded-md flex flex-col gap-2 shadow shadow-lg">
        {/* <div className="flex items-center justify-between">
          <span>Арлын дугааp</span>
          <span className="font-bold">{data?.cabinNumber}</span>
        </div> */}
        <div className="flex items-center justify-between">
          <span>Үйлдвэрлэгч</span>
          <span className="font-bold">{data?.markName}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Загвар</span>
          <span className="font-bold">{data?.modelName}</span>
        </div>
        {/* <div className="flex items-center justify-between">
          <span>Төрөл</span>
          <span className="font-bold">{data?.type}</span>
        </div> */}
        <div className="flex items-center justify-between">
          <span>Үйлдвэрлэсэн улс</span>
          <span className="font-bold">{data?.countryName}</span>
        </div>
        <div className="flex items-center justify-between">
          <span>Үйлдвэрлэсэн он</span>
          <span className="font-bold">{data?.buildYear}</span>
        </div>
        {/* <div className="flex items-center justify-between">
          <span>Импортлогдсон огноо</span>
          <span className="font-bold">
            {dayjs(data?.importDate)?.format("YYYY/MM/DD")}
          </span>
        </div> */}
      </div>
    </div>
  );
};

export default Index;
